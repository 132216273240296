<template>
  <ul class="list-slot">
    <li
      v-for="(singleSlot, index) in slots"
      :key="index"
      :class="slotSelected == singleSlot[0] ? 'active' : ''"
      @click="$emit('selectSlot', singleSlot[0])"
    >
      <p
        class="channel_info"
      >
        <b>SLOT {{ singleSlot[0] }}</b>
        <!-- <b>SLOT {{ singleSlot[0] }}</b> • {{ singleSlot.modelo }} • {{ singleSlot.tipoSenal }} • {{ singleSlot.tipo }} • Ch: {{ singleSlot.canales }} -->
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    slots: {
      type: Array,
      required: true,
      default: () => [],
    },
    slotSelected: {
      type: Number,
      required: false,
      default: null,
    },
  },
}
</script>
