<template>
  <div class="rounded bg-white mb-2 shadow">
    <div class="header-list">
      <h3>Slot: {{ currentSlot }} || Canales: {{ channels.length }}</h3>
    </div>
    <ul
      class="list-slot"
    >
      <li
        v-for="(item, index ) in channels"
        :key="index"
        :class="chSelected == index ? 'active' : ''"
        @click="$emit('chSelect', index)"
      >
        <p class="channel_info">
          <span class="channel_status" /><b>Canal {{ item.canal }} : {{ item.tagName }}</b> - <i>{{ item.description || item.comment }}</i>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    currentSlot: {
      type: Number,
      default: null,
      required: true,
    },
    channels: {
      type: Array,
      required: true,
      default: () => [],
    },
    chSelected: {
      type: Number,
      default: -1,
      required: true,
    },
  },
}
</script>
