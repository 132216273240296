<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-card no-body>
          <div class="header-list">
            <div>
              <div data-app>
                <v-autocomplete
                  v-model="plcSelected"
                  label="PLC"
                  :items="plcList"
                  @change="plcChange"
                />
              </div>
            </div>
            <div v-if="armarios.length">
              <div data-app>
                <v-autocomplete
                  v-model="armarioSelected"
                  label="Armarios"
                  :items="armarios"
                  @change="armarioChange"
                />
              </div>
            </div>
          </div>
          <list-slots
            v-if="armarioSelected"
            :slots="slots"
            :slot-selected="slotSelected"
            @selectSlot="slotChange"
          />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <ListChannels
          v-if="channels.length"
          :current-slot="slotSelected"
          :channels="channels"
          :ch-selected="channelSelected"
          @chSelect="channelChange"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <SingleChannel
          v-if="channelSelected >= 0"
          :channel="channels[channelSelected]"
          :index-ch="channelSelected"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'
import ListSlots from './Components/Slots.vue'
import ListChannels from './Components/Channels.vue'
import SingleChannel from './Components/SingleChannel.vue'
import repoElencos from '@/../apis/Calibraciones'

export default {
  components: {
    BCard, BCol, BRow, ListSlots, ListChannels, SingleChannel,
  },
  data() {
    return {
      // Variables para almacenamiento
      armarioSelected: '',
      plcSelected: '',
      // Variables de funcionamiento
      slotSelected: null,
      armarios: [],
      plcList: [],
      slots: [],
      channels: [],
      channelSelected: -1,
      cacheChannels: [],
    }
  },
  async mounted() {
    const result = await repoElencos.actions.getPLCList()
    this.plcList = result.data
  },
  methods: {
    async plcChange() {
      const result = await repoElencos.actions.getArmarios(this.plcSelected[0])
      this.armarios = result.data
    },
    async armarioChange() {
      this.cacheChannels = []
      this.slots = []
      this.channels = []
      this.slotSelected = null
      const result = await repoElencos.actions.getSlots(this.armarioSelected[0], this.plcSelected[0])
      this.slots = result.data
    },
    async slotChange(event) {
      this.slotSelected = parseInt(event, 10)
      this.channelSelected = -1
      this.channels = []
      if (!this.cacheChannels[this.slotSelected]) {
        const result = await repoElencos.actions.getChannels(this.armarioSelected[0], this.plcSelected[0], this.slotSelected)
        this.cacheChannels[this.slotSelected] = result.data
      }
      this.channels = this.cacheChannels[this.slotSelected]
    },
    channelChange(event) {
      this.channelSelected = event
    },
  },
}
</script>

<style>
.channel_status{
  width: 10px;
  height: 10px;
  background-color: #3fb100;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}
.channel_info{
  border-bottom: 1px solid #fe6f17a3;
  padding:1.5rem;
  margin-bottom: 0;
}
.header-list{
  padding: 1.5rem;
  border-bottom: 1px solid #fe6f17a3;
}
.list-slot li:hover{
  background: #d1d5db;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-layout .list-slot li:hover{
  background: #242b3d;
}
.select-armario{
  width: 80%;
}
.list-slot li.active{
  background: #fe6f17;
  color: white;
}
</style>
