import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    enviarRegistro(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertCalibracion', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    obtenerRegistros(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/getDataCalib', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    obtenerElencos(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/elencos', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPLCList() {
      return new Promise((resolve, reject) => {
        axios
          .get('/plc/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postFileTags(body, plc) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/plc/${plc}`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getArmarios(plc) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/plc/ar/${plc}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSlots(armario, plc) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/plc/slots/${armario}`, { params: { plc } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChannels(armario, plc, slot) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/plc/channels/${armario}`, { params: { plc, slot } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
