<template>
  <b-card
    v-if="channel.ID"
    title="Editar Canal"
  >
    <b-form-group label="ID">
      <b-form-input
        v-model="channel.ID"
        type="text"
        autocomplete="off"
        placeholder="ID"
        :disabled="true"
      />
    </b-form-group>
    <b-form-group label="Tag">
      <b-form-input
        v-model="channel.tagName"
        type="text"
        autocomplete="off"
        placeholder="Tag"
        :disabled="true"
      />
    </b-form-group>
    <b-form-group label="Descripción">
      <b-form-input
        v-model="channel.description"
        type="text"
        autocomplete="off"
        placeholder="Descripción"
        :disabled="true"
      />
    </b-form-group>
    <b-form-group label="Comentario PLC">
      <b-form-input
        v-model="channel.comment"
        type="text"
        autocomplete="off"
        placeholder="Comentario PLC"
        :disabled="true"
      />
    </b-form-group>
  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup, BFormInput, BCard,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
}
</script>
